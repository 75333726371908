@import "../../../app/styles/_vars.scss";
@import "../../../app/styles/media-queries.scss";


.button {
  display: inline-flex;
  padding: 12px 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  white-space: normal;
  word-wrap: break-word;
  @include button-font;

  @include desktop-xl {
  }
  @include desktop-l {
  }
  @include desktop-m {
  }
  @include tablet-l {
    font-size: clamp(16px, 4vw, 28px);
  }
  @include tablet-m {
    font-size: clamp(14px, 3.5vw, 24px);
  }
  @include mobile-l {
    font-size: clamp(16px, 4vw, 22px);
  }
  @include mobile-m {
	font-size: clamp(12px, 4vw, 18px);
	max-width: 80%;
  }

  &.fill {
        box-shadow: $red-button-shadow;
		background: $primary-gradient-animation1;
		color: $color-white;
		background-size: 250%;
		animation: glowing 3s linear infinite;

		&:hover {
			background: $primary-gradient-light;
			animation: none;
		}

		&:enabled:active {
			background: $color-primary-dark;
			animation: none;
		}

		&:disabled {
			color: $color-white;
			background: $color-gray-300;
			box-shadow: $gray-button-shadow;
			animation: none;
		}
  }

  &.outline {
    border: 1px solid $color-primary;
    background: $color-white;
    color: $color-primary;

    @include responsive($desktop-l) {
      font-size: clamp(16px, 1.5vw, 18px)
  }

    &:hover {
      color: $color-primary-light;
      border: 1px solid $color-primary-light;
    }

    &:enabled:active {
      box-shadow: $red-button-inner-shadow;
    }

    &:disabled {
      color: $color-gray-300;
      border: 1px solid $color-gray-300;
    }
  }

  &.transparent {
    border: none;
    background: transparent;
    color: $color-primary;
    padding: 0;
  }
}

@keyframes glowing {
    0% { background-position: 0% 0; }
    50% { background-position: 100% 0; }
    100% { background-position: 0% 0; }
}

