@import "../../../../app/styles/vars";
@import "../../../../app/styles/media-queries.scss";

.contacts {
  width: 100%;
  background-color: $color-secondary;
}

.copyright {
  width: 100%;
  color: $color-white;
  margin-top: 30px;
  font-size: clamp(12px, 3vw, 16px);
  text-align: center;
  background: $primary-gradient;
  padding: 30px 50px;
  
  @include tablet-l {
    background: none;
    background-color: $color-secondary;
    color: $color-gray-300;
    padding: 0 50px 40px 50px;
  }
  @include tablet-m {
    padding: 0 40px 40px 40px;
  }
  @include mobile-l {
    padding: 0 30px 40px 30px;
    white-space: pre-line;
  }
  @include mobile-m {
    padding: 0 10px 40px 10px;
    font-size: 10px;
  }
}
