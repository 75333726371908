@import '../../../app/styles/vars';

.hr {
	margin: 0;
	padding: 0;
	border: none;
	border-bottom: $color-primary 5px solid;
	width: 100%;
	opacity: 0;
  transform: scale(0);
	animation: slide 2s forwards;
}

@keyframes slide {
  to {
    opacity: 1;
    transform: scale(1);
  }
}
