@import "../../../../app/styles/vars";
@import "../../../../app/styles/media-queries.scss";

.mortgageStepCard {
  background-color: $color-white;
  box-shadow: $block-shadow;
  border-radius: 7px;
  height: 350px;
  overflow: hidden;
  padding: 35px 60px 20px;

  @include desktop-l {
    padding: 35px 55px 20px;
  }

  @include desktop-m {
    padding: 30px 40px 20px;
    height: 320px;
  }

  @include tablet-l {
    height: 300px;
  }

  @include tablet-m {
    height: 280px;
    padding: 25px 30px 20px;
  }
}

.mortgageStepCard img {
  padding-left: 5px;
  margin-bottom: -7px;
  height: 35px;

  @include desktop-m {
    height: 30px;
    margin-bottom: 0px;
  }

  @include tablet-l {
    height: 25px;

    @media (max-width: 750px) {
      height: 20px;
    }
  }
}

.numberMortgageStep {
  width: 100%;
  height: 90px;
  padding-bottom: 10px;
  font-size: 4rem;
  color: $color-primary;
  line-height: 0.5em;
  border-bottom: 2px solid $color-primary;
  margin-bottom: 40px;

  @include desktop-xl {
    font-size: 3.7rem;
  }

  & span {
    font-size: 2rem;
    line-height: 0.4em;
    margin-right: 8px;
    color: $color-gray-300;

    @include desktop-xl {
      font-size: 1.7rem;
    }
  }
}

.textMortgageStep {
  width: 100%;
  height: 100%;
  text-align: center;
  color: $color-gray-600;
  line-height: 1.3em;
  font-size: clamp(26px, 2.5vw, 34px);
  
  @include desktop-xl {
    font-size: clamp(26px, 2.2vw, 30px);
    &[lang="ru"] {
      font-size: clamp(26px, 2.2vw, 28px);
      hyphens: auto;
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }
  }
  @include desktop-l {
    font-size: clamp(22px, 2vw, 28px);
    &[lang="ru"] {
      font-size: clamp(22px, 2vw, 28px);
    }
  }
  @include desktop-m {
    font-size: clamp(20px, 2.4vw, 24px);
  }
  @include tablet-l {
    font-size: clamp(18px, 2.7vw, 24px);
  }
  @include tablet-m {
    font-size: clamp(18px, 2.9vw, 22px);
    &[lang="ru"] {
      @media (max-width: 700px) {
        font-size: clamp(18px, 2.9vw, 22px);
      }
    }
  }
  @include mobile-l {
    font-size: clamp(12px, 4vw, 16px);
  }
  @include mobile-m {
  }
}

.picProcessMortgage {
  position: relative;
  width: 100%;
  height: 350px;
  border-radius: 7px;
  overflow: hidden;
  box-shadow: $block-shadow;

  @include desktop-m {
    height: 320px;
  }

  @include tablet-l {
    height: 300px;
  }
  
  @include tablet-m {
    height: 280px;
  }
}

.picProcessMortgage img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}
