html {
  box-sizing: border-box;
  font-size: 100%;
}

*, p,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background: none;
  border: none;
  padding: 0;
}
