@import "../../../../app/styles/vars";
@import "../../../../app/styles/media-queries.scss";

.myServices {
  width: 100%;
  margin-bottom: 30px;
}

.myServicesBlock {
  width: 100%;
  gap: 20px;
  @include desktop-xl {
  }  
  @include desktop-l {
  }
  @include desktop-m {
  }
  @include tablet-l {
    max-width: 700px;
    padding: 30px 50px 40px 50px;
  }
  @include tablet-m {
    max-width: 700px;
    padding: 30px 40px 40px 40px;
  }
  @include mobile-l {
    padding: 30px 30px 40px 30px;
  }
  @include mobile-m {
    padding: 30px 10px 40px 10px;
  }

}
