@import '../../../app/styles/vars';

@keyframes popup {
	0% {
		transform: scale(1);
		opacity: 0.5;
	}
	50% {
		transform: scale(1.3);
	}
	60% {
		transform: scale(1.2);
	}
	70% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

.modalWrapper {
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	z-index: 100;
	width: 100%;
	min-height: 100vh;
	background: $color-black-transparent;
	animation: popup 0.7s;
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);    
}
