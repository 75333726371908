@import '../../../../app/styles/vars';
@import '../../../../app/styles/fonts.scss';
@import '../../../../app/styles/media-queries.scss';


.questionBlockContainer{
    gap: 20px;

    @include responsive($desktop-l) {
        padding-left: 20px;
    }
}


.questionText {
    @include questionnaire-font;
    width: 280px;
    height: 100px;
    padding: 10px;
    text-align: center;
}

.questionButtons{
    width: 280px;
    padding: 0px 10px;
    gap: 30px;

}

