@import '../../../../app/styles/vars';
@import '../../../../app/styles/media-queries.scss';

.carousel {
    display: flex;
    justify-content: center;
    margin: 5px 0 30px 0;

    @media all and (min-width: 1024px) {
        height: 480px;
        max-width: 640px;
    }

    @include tablet-l() {
        height: 480px;
        max-width: 660px;
        padding: 0 40px;
    }

    @media all and (max-width: 670px) {
        height: 420px;
    }

    @include mobile-l() {
        padding: 0 30px;
    }

    @media all and (max-width: 500px) {
        height: 380px;
    }

    @media all and (max-width: 450px) {
        height: 350px;
    }

    @media all and (max-width: 400px) {
        height: 300px;
    }

    @include mobile-m() {
        height: 280px;
    }

    .carousel-indicators {
        margin: 0 !important;
        width: 100% !important;
        padding: 0 10% !important;
        margin: 10px 0 !important;

        [data-bs-target] {
            width: 20px !important;
            height: 20px !important;
            border-top: none;
            border-bottom: none;
            border-radius: 50%;
            background-color: #dadfe5 !important;
            margin: 10px 7px !important;

            @media all and (max-width: 670px) {
                width: 18px !important;
                height: 18px !important;
            }

            @media all and (max-width: 590px) {
                margin: 10px 6px !important;
            }

            @media all and (max-width: 450px) {
                margin: 10px 5px !important; 
            }

            @media all and (max-width: 400px) {
                width: 16px !important;
                height: 16px !important;
                margin: 10px 4px !important; 
            }

            @include mobile-m() {
                width: 15px !important;
                height: 15px !important;
                margin: 10px 3px !important;
            }
        }

        .active {
            background-color: $color-gray-200 !important;
        }
    }

    .carousel-inner {
        height: 70% !important;
        width: 100% !important;
        margin: 20px 12% 0 12%;
        padding: 10px 20px 20px 20px;
        border-radius: 5px;
        box-shadow: $block-shadow;

        @media all and (min-width: 1024px) {
            height: 83% !important;
        }

        @include tablet-l() {
            height: 83% !important;
            margin: 20px 5% 0 5%;
        }

        @media all and (max-width: 670px) {
            height: 81% !important;
            margin: 20px 7% 0 7%;
        }

        @media all and (max-width: 450px) {
            height: 79% !important;
        }

        @media all and (max-width: 400px) {
            height: 76% !important;
        }

        @include mobile-m() {
            height: 74% !important;
            margin: 20px 3% 0 3%;
        }

        .carousel-item {
            max-height: 100%;
            max-width: 100% !important;
            overflow-y: auto;

            &::-webkit-scrollbar-track {
                background-color: #f1f1f1;
                border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $color-gray-200;
                border-radius: 5px;

                &:hover {
                    background-color: lighten($color-gray-200, 10%);
                }
            }

            &::-webkit-scrollbar {
                width: 6px;
                border-radius: 5px;
            }

            img {
                height: 100% !important;
                width: 100% !important;
                object-fit: contain;
            }
        }
    }

    .carousel-control-next,
    .carousel-control-prev {
        width: 10% !important;


        &-icon {
            width: 35px !important;
            height: 35px !important;
        }
    }

    .carousel-control-next {
        @media all and (max-width: 670px) {
            margin-right: 10px;
        }

        @include mobile-m() {
            margin-right: 0;
        }
    }

    .carousel-control-prev {
        @media all and (max-width: 670px) {
            margin-left: 10px;
        }

        @include mobile-m() {
            margin-left: 0;
        }
    }

    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 15 15%27 fill=%27%23c1c9d2%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
    }

    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 15 15%27 fill=%27%23c1c9d2%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e") !important;
    }
}
