@import "../../../../app/styles/_vars.scss";

.topGetGuide {
  width: 300px;
  padding: 0 0 0 40px;
}

.flag {
  width: 60px;
  height: 82px;
  background-color: $color-primary;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%);
  font-weight: 700;
  margin-right: 20px;
  line-height: 0.9em;
  color: $color-white;

  .firstRow {
    font-size: 18px;
    align-self: flex-start;
    padding-left: 7px;
  }

  .secondRow {
    font-size: 21px;
    align-self: center;
  }
}

.slogan {
  @include article-header-font;
  color: $color-primary;
  line-height: 1.1em;
  width: 170px;
  padding-bottom: 10px;

  .firstRow {
    font-size: 23px;
    align-self: flex-start;
  }

  .secondRow {
    font-size: 30px;
    align-self: flex-end;
  }
}

.slogan[lang="ru"] {
  .firstRow {
    font-size: 27px;
  }

  .secondRow {
    font-size: 20px;
  }
}
