@import '../../../../app//styles/vars';

.rateForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 20px;
    gap: 20px;
}

.rate {
    @include questionnaire-font;
    letter-spacing: 0.225px;
    text-transform: uppercase;
}
