@import '../../../app/styles/_vars.scss';
@import '../../../app/styles/media-queries.scss';

.input {
	border-radius: 5px;

	&.form,
	&.calculate {
		display: flex;
		align-items: center;
		gap: 10px;
		flex-shrink: 0;
		@include input-font;
		outline: none;
		@include responsive-font-size(16px, 16px, 16px, 18px);
	}

	&.form {
		color: $color-black;
		width: 270px;
		height: 35px;
		padding: 10px 20px;
		border: 1px solid $color-gray-200;
		background: $color-white;
		box-shadow: $block-shadow;
		-webkit-box-shadow: $block-shadow;
		-moz-box-shadow: $block-shadow;
		-ms-box-shadow: $block-shadow;
		-o-box-shadow: $block-shadow;

		&::placeholder {
			color: $color-gray-200;
		}
	}

	&.calculate {
		color: $color-gray-300;
		height: 25px;
		min-width: 100px;
		width: 120px;
		min-height: 25px;
		padding: 2px 10px;
		border: 1px solid $color-gray-200;
		background: $color-gray-100;

		@include responsive($tablet-m) {
			height: 30px;
		}
		@include responsive($tablet-l) {
			width: 150px;
		}
	
		@include responsive($desktop-xl) {
			width: 200px;
			height: 35px;
		}

		&::placeholder {
			flex: 1 0 0;
			color: $color-gray-300;
			
		}

		&:focus {
			color: $color-black;
			border: 1px solid $color-gray-300;
			background: $color-white;
		}
	}

	&.range {
		position: relative;
		height: 5px;
		width: 300px;
		background-color: $color-gray-100;
		-webkit-appearance: none;
		appearance: none;

		@include responsive($tablet-m) {
			width: 340px;
		}

		@include responsive($tablet-l) {
			width: 440px;
		}

		@include responsive($desktop-l) {
			width:clamp(440px, 30vw, 540px);
		}

		@include responsive($desktop-xl) {
			width: 580px;
		}

		&::-webkit-slider-runnable-track,
		&::-moz-range-track {
			z-index: 0;
		}


		&::-webkit-slider-thumb {
			width: 16px;
			height: 16px;
			background: $color-white;
			border: 1px solid $color-gray-300;
			-webkit-appearance: none;
			appearance: none;
			cursor: pointer;
			border-radius: 50%;
			position: relative;
			z-index: 1;
		}

		&::-moz-range-thumb {
			width: 15px;
			height: 15px;
			background: $color-white;
			border: 1px solid $color-gray-300;
			cursor: pointer;
			border-radius: 50%;
			position: relative;
			z-index: 1;
		}
	}
}

.error {
	font-family: $font-main;
	margin-top: 5px;
	font-size: 14px;
	color: $color-primary;
	margin-left: 3px;
	align-self: flex-start;
}

