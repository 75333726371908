@import "../../../../app/styles/vars";
@import "../../../../app/styles/media-queries.scss";

.serviceCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 7px;
  box-shadow: $block-shadow;
  white-space: pre-line;
  text-align: center;
  gap: 14px;
  line-height: 1.3em;
  background-color: $color-white;
  cursor: pointer;
  color: $color-black;
  
  & img {
    height: 32%;
  }

  @include tablet-m {
    font-size: clamp(14px, 3.5vw, 20px);
  }
  @include mobile-l {
    font-size: clamp(14px, 3.5vw, 20px);
  }
  @include mobile-m {
  }
}

.serviceCardBtn {
  background: $primary-gradient-animation1;
  color: $color-white;
  & img {
    width: 60%;
    height: auto;
  }
    background-size: 250%;
		animation: glowing 3s linear infinite;
}
@keyframes glowing {
    0% { background-position: 0% 0; }
    50% { background-position: 100% 0; }
    100% { background-position: 0% 0; }
}


.serviceCardBtn:hover {
  background: $primary-gradient-light;
  animation: none;
}

.motion {
  aspect-ratio: 9.5 / 9;
  flex: 1 1 30%;
}
