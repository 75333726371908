@import '../../../../app/styles/vars';

.slideMenu {
    position: fixed;
    bottom: 81px;
    width: 100%;
    padding: 40px 70px;
    z-index: 9;
    background: $primary-gradient;
    color: white;
    will-change: transform;
    gap: 40px;
  }
  
  .menuList {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: $font-main;
    width: 100%;

    li {
      font-family: $font-main;
      text-align: right;
      font-size: 18px;
      padding: 5px;
      font-style: normal;
      font-weight: 400;
      line-height: 200%; 
      width: 100%;
      letter-spacing: 0.3px;
      text-transform: uppercase;

      &:hover,
      :active {
          font-weight: 700;
          background: linear-gradient(100deg, rgba(153,11,49,0) 10%, rgba(0,0,0,0.2091430322128851) 100%);
      }
    }
  }
  