@import "../../../app/styles/vars";
@import "../../../app/styles/media-queries.scss";

.sectionTitle {
  width: 100%;
  min-height: 70px;
  background-color: $color-white;
  position: relative;
}

.sectionTitle::after {
  content: "";
  width: 100%;
  height: 5px;
  background-color: $color-primary;
  position: absolute;
  z-index: 4;
  opacity: 0;
  transform: scale(0);
  animation: slide 2s forwards;
}

.titleText {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin: 0;
  @include article-header-font;
  text-align: center;
  word-wrap: normal;
  color: $color-primary;
  font-size: clamp(30px, 9vw, 40px);
  background-color: $color-white;
  z-index: 5;
  @include desktop-xl {
    font-size: clamp(26px, 8vw, 35px);
  }
  @include tablet-l {
    font-size: clamp(24px, 6vw, 30px);
  }
  @include mobile-l {
    font-size: clamp(22px, 5vw, 28px);
  }
  @include mobile-m {
    &[lang="ru"] {
      font-size: 18px;
    }
  }
}

.secondary {
  background-color: $color-secondary;
}

@keyframes slide {
  to {
    opacity: 1;
    transform: scale(1);
  }
}
