@import '../../../../app/styles/_vars.scss';

.thanks {
	width: 300px;
	min-width: 300px;
	gap: 10px;
	color: $color-black;
	text-align: center;
	font-size: 16px;
	font-weight: 400;
}