@import '../../../../app/styles/fonts.scss';
@import '../../../../app/styles/vars';
@import '../../../../app/styles/media-queries.scss';

.titleQ {
    gap: 10px;

    svg{
        width: 17.607px;
        height: 40.67px;
        flex-shrink: 0;
        fill: $color-primary;
        stroke-width: 0.283px;
    }

    .svgQ {
        animation: tilt-animation 2s forwards;
    }

    h2{
        @include article-header-font;
        color: $color-primary;

        @include responsive($desktop-l) {
            font-size: clamp(20px, 1.7vw, 36px);
        }
    
    }   
}

@keyframes tilt-animation {
    0% {
        transform: rotate(0deg);
    }
    20% {
        transform: rotate(-20deg);
    }
    40% {
        transform: rotate(10deg);
    }
    60% {
        transform: rotate(-10deg);
    }
    80% {
        transform: rotate(5deg);
    }
    90% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}


