.flex {
	display: flex;
  }

  .flexWrap {
    flex-wrap: wrap;
  }
  
  .row {
	flex-direction: row;
  }
  
  .column {
	flex-direction: column;
  }
  
  .justifyCenter {
	justify-content: center;
  }
  
  .justifyStart {
	justify-content: start;
  }
  
  .justifyCenter {
	justify-content: center;
  }
  
  .justifyEnd {
	justify-content: flex-end;
  }
  
  .justifyBetween {
	justify-content: space-between;
  }
  
  .alignStart {
	align-items: start;
  }
  
  .alignCenter {
	align-items: center;
  }
  
  .alignEnd {
	align-items: flex-end;
  }
  
  .max {
	width: 100%;
  }