@import "../../../app/styles/vars";
@import "../../../app/styles/media-queries.scss";

.logoStack {
  position: relative;
  width: 245px;
  padding: 2px;
}

.rinata {
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  @include mobile-m {
    width: 200px;
  }
}

.rightContainer {
  position: relative;
  padding-right: 8px;
  z-index: 98;
}

.ra {
  margin-bottom: 5px;
  @include mobile-m {
    width: 53px;
  }
}

.mortgageAdviser {
  @include mobile-m {
    width: 56px
  }
}
