@import "../../../../app/styles/vars";
@import "../../../../app/styles/media-queries.scss";

.mortgageProcess {
  width: 100%;
  height: 100%;
  background-color: $color-secondary;
  gap: 60px;
  padding-top: 40px;
  
  @include mobile-l {
    gap: 20px;
    padding-top: 30px;
  }
  
}
