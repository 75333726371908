@import "../../../../app/styles/_vars.scss";

.mainGetGuide {
  width: 300px;
  padding-bottom: 40px;
}

.mainGetGuide img {
  margin-right: 28px;
}

.firstRow {
  font-size: 28px;
  line-height: 1em;
}

.secondRow {
  font-size: 26px;
  line-height: 0.8em;
  margin-bottom: 10px;
}

.thirdRow {
  font-size: 25px;
  line-height: 1em;
  color: $color-primary;
}

.fourthRow {
  font-size: 19px;
  line-height: 0.8em;
  color: $color-primary;
}

.mainGetGuide[lang="ru"] {
  .firstRow {
    font-size: 24px;
  }
  .secondRow {
    font-size: 40px;
  }
  .thirdRow {
    font-size: 20px;
  }
  .fourthRow {
    font-size: 18px;
  }
}
