@import "../../../../app/styles/vars";
@import "../../../../app/styles/media-queries.scss";

.headerTop {
  width: 100%;
  background: $primary-gradient;
  padding: 30px 25px 55px 0;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 55%);
  position: relative;
  z-index: 999;
  @include tablet-l {
    padding: 20px 50px 40px 0;
  }
  @include tablet-m {
    padding: 20px 40px 40px 0;
  }
  @include mobile-m {
    padding: 20px 10px 55px 0;
  }
}

.headerTop::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 66px;
  clip-path: polygon(0 0, 100% 90%, 100% 100%, 0% 10%);
  background-color: $color-white-transparent;
  @include tablet-l {
    height: 54px;
  }
  @include tablet-m {
    height: 54px;
  }
  @include mobile-m {
    height: 60px;
  }
}
