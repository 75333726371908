@import "../../../../app/styles/vars";
@import "../../../../app/styles/media-queries.scss";

.resultText {
  width: 280px;
  min-width: 280px;
  padding: 24px 20px;
}

.resultText h6 {
  @include article-header-font;
  color: $color-primary;
  margin-bottom: 10px;

  @include responsive($desktop-l) {
    font-size: clamp(20px, 1.5vw, 22px)
}
}

.resultText p {
  @include questionnaire-font;
  text-align: center;

  @include responsive($desktop-l) {
    font-size: clamp(20px, 1.5vw, 22px)
}
}
.questionButtons{
  width: 280px;
  margin-top: 24px;
  margin-bottom: -25px;
}
