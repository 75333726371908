@import "../../../../app/styles/vars";
@import "../../../../app/styles/media-queries.scss";

.bottomSlogan {

  width: 100%;
  @include article-header-font;
  font-size: clamp(40px, 4vw, 55px);
  line-height: 1.2em;
  position: relative;
  justify-content: flex-end;
  &[lang="ru"] {
    font-size: clamp(40px, 4vw, 55px);
  }

  @include desktop-xl {
    font-size: clamp(40px, 3.3vw, 45px);
    &[lang="ru"] {
        font-size: clamp(35px, 3vw, 40px);
    }
  }
  @include desktop-l {
    font-size: clamp(35px, 3vw, 42px);
    &[lang="ru"] {
      font-size: clamp(34px, 3vw, 36px);
    }
  }
  @include desktop-m {
    font-size: clamp(28px, 3vw, 34px);
    &[lang="ru"] {
      font-size: clamp(25px, 3.5vw, 28px);
    }
  }
  @include tablet-l {
    font-size: 5vw;
    background-color: $color-secondary;
    justify-content: center;
    padding: 15px 0;
    &[lang="ru"] {
      font-size: clamp(38px, 5vw, 55px);
    }
  }
  @include mobile-l {
    font-size: clamp(28px, 6.5vw, 45px);
    padding: 20px 0;
    &[lang="ru"] {
      font-size: clamp(25px, 6vw, 35px);
    }
  }
  @include mobile-m {
    font-size: clamp(18px, 7vw, 24px);
    padding: 12px 0;
    &[lang="ru"] {
      font-size: clamp(17px, 6vw, 22px);
    }
  }
}

.bottomSlogan::before {
  content: "";
  height: 8px;
  width: 100%;
  background-color: $color-white-transparent;
  position: absolute;
  top: -4px;
  left: 0;

  @media (min-width: $tablet-l) {
    display: none;
  }
}
