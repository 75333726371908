@import '../../../../app/styles/vars'; 
@import '../../../../app/styles/media-queries.scss';

.main {
    margin-top: 40px;
    padding-bottom: 40px;

    @include responsive($tablet-m) {
        padding-bottom: 0px;
    }
}

.currentRate {
    color: $color-black;
    padding: 20px 30px;
    width: 100%;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    @include responsive-font-size(20px, 22px, 24px, 26px);

    @include responsive($tablet-m) {
        padding: 20px;
    }
}

.mobileContent {
    width: 100%;
    max-width: 1920px;
    padding: 20px 80px;
    justify-content: center;
}

.desktopContent {
    width: 100%;
    max-width: 1920px;
    padding: 20px 80px;
    gap: 30px;
}

.safariForm {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    flex-wrap: wrap;
    background-color: $color-white;
    font-family: $font-main;

    @include responsive($tablet-m) {
        height: 400px;
        gap: 20px;
    }

    @include responsive($tablet-l) {
        gap: 30px;
    }

    @include responsive($desktop-xl) {
        height: 440px;
        gap: 50px;
        flex-grow: 1;
    }
}


.form {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    background-color: $color-white;
    font-family: $font-main;

    @include responsive($tablet-m) {
        height: 400px;
        gap: 20px;
    }

    @include responsive($tablet-l) {
        gap: 30px;
    }

    @include responsive($desktop-xl) {
        height: 440px;
        gap: 50px;
    }
}

.error {
    font-family: $font-main;
    margin-bottom: 15px;
    color: $color-primary;
    @include responsive-font-size(16px, 16px, 16px, 18px);

    @include responsive($tablet-m) {
        margin-bottom: 5px;
    }
}