@import '../../../../app/styles/vars';
@import '../../../../app/styles/media-queries.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 30px 70px 30px;

    @media all and (max-width: 450px) {
      padding: 20px 20px 70px 20px;
    }
  
    .inputFile {
        position: relative;
        display: inline-block;

        span {
            display: flex;
            padding: 12px 28px;
            text-align: center;
            border-radius: 4px;
            @include button-font;
            border: 1px solid $color-primary;
            background: $color-white;
            color: $color-primary;

            &:hover {
                color: $color-primary-light;
                border: 1px solid $color-primary-light;
                cursor: pointer;
            }

            &:enabled:active {
                box-shadow: $red-button-inner-shadow;
            }
        }

        input[type=file] {
            position: absolute;
            z-index: -1;
            opacity: 0;
            display: block;
            width: 0;
            height: 0;
        }
        
        input[type=file]:disabled + span {
            color: $color-gray-300;
            border: 1px solid $color-gray-300;
        }
    }
        
    .reviewsContainer {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      width: 100%;
  
      .imgContainer {
        width: 19%;
        box-shadow: $block-shadow;
        padding: 20px;
        margin: 0.5%;

        @include desktop-l {
          width: 24%;
        }

        @include desktop-m {
          width: 31%;
          margin: 1%;
        }

        @include tablet-m {
          width: 46%;
          margin: 1.5%;
        }

        @include mobile-l {
          width: 96%;
          margin: 2.5% 1.5%;
        }
  
        div {
          height: 300px;
          overflow: auto;

          @include desktop-l {
            height: 240px;
          }

          @include desktop-m {
            height: 280px;
          }

          @include tablet-l {
            height: 240px;
          }

          @include tablet-m {
            height: 280px;
          }

          @include mobile-l {
            height: 350px;
          }

          @media all and (max-width: 450px) {
            height: 300px;
          }

          @include mobile-m {
            height: 250px;
          }
  
          &::-webkit-scrollbar-track {
            background-color: #f1f1f1;
            border-radius: 5px;
          }
  
          &::-webkit-scrollbar-thumb {
            background-color: $color-gray-200;
            border-radius: 5px;
  
            &:hover {
              background-color: lighten($color-gray-200, 10%);
            }
          }
  
          &::-webkit-scrollbar {
            width: 6px;
            border-radius: 5px;
          }
        }
  
        p {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding-top: 10px;
          border-top: 1px solid #E7CBCB;
          font-family: $font-main;
  
          span {
            width: 50%;
            color: $color-primary;
            text-transform: uppercase;
            font-size: 20px;
          }
        }
  
        img {
          width: 100%;
        }
  
        .delBtn {
          width: 50%;
        }
      }
    }

    .addBtnContainer {
        margin-top: 10px;
    }
  
    .smallWarn {
      color: red;
      margin-top: 10px;
    }
  
    .warning {
      color: red;
      margin: 20px 0;
      text-align: center;
    }
  
    .info {
      color: green;
      margin: 20px 0;
    }
  }