@import "../../../../app/styles/vars";
@import "../../../../app/styles/media-queries.scss";

.bigHeaderInfoBlock {
  width: 49vw;
  max-width: 900px;
  padding-right: 80px;
  padding-bottom: 20px;
  @media (-webkit-min-device-pixel-ratio: 2) {
    width: 43vw;
  }
  @include desktop-l {
    padding-right: 60px;
  }
  @include desktop-m {
    padding-right: 50px;
    max-width: 630px;
  }
}
