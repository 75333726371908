@import '../../../../app/styles/vars'; 
@import '../../../../app/styles/media-queries.scss';

.shadowCalculatorContainer {
    height: 170px;
    padding: 0px 20px;
    gap: 20px;
    font-family: $font-main;

    > div  {
        text-align: center;
        width: 140px;
        height: 140px;
        padding: 18px 20px;
        border-radius: 7px;
        background: $color-white;
        box-shadow: $block-shadow;

        @include responsive($tablet-m) {
            width: 150px;
            height: 150px;
        }

        @include responsive($tablet-l) {
            width: 170px;
            height: 170px;
        }
    
        @include responsive($desktop-xl) {
            width: 200px;
            height: 200px;
        }

        > span {
            display: flex;
            text-transform: uppercase;
            font-style: normal;
            line-height: normal;

            &:nth-child(1) {
                color: $color-primary;
                height: 36px;
                font-weight: 400;
                letter-spacing: 0.188px;
                @include responsive-font-size(13px, 15px, 17px, 21px);

                @include responsive($tablet-l) {
                    height: 60px;
                }
            }
            
            &:nth-child(2) {
                color: $color-black;
                font-size: 18px;
                font-weight: 700;
                @include responsive-font-size(18px, 20px, 24px, 28px);
            }
            
            &:nth-child(3) {
                height: 20px;
                font-size: 13px;
                color: $color-black;
                font-weight: 400;
                letter-spacing: 0.2px;
                @include responsive-font-size(13px, 15px, 17px, 21px);
            }
        }
    }
}