@import "../../../../app/styles/vars";

.contactButton {
  gap: 10px;
}

.contactButtonIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-white;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  box-shadow: $red-button-shadow;
}

.contactButtonIcon:hover,
.contactButtonIcon:active {
  background-color: $color-primary;
}

.contactButtonIcon svg,
.contactButtonIcon svg {
  height: 20px;
  width: 20px;
  fill: $color-primary;
}

.contactButtonIcon:hover svg,
.contactButtonIcon:active svg {
  fill: $color-white;
}

.contactButtonLabel {
  color: $color-primary;
  font-size: clamp(16px, 2vw, 22px);
}

.contactButtonLabel:hover {
  color: $color-black;
}
