@import '../../../../app/styles/media-queries.scss';

.slider {
    display: none;

    @include tablet-l() {
        display: block;
    }
}

.desktopSlider {
    display: none;

    @media all and (min-width: 1025px) {
        display: block;
        max-width: 100%;
        box-sizing: border-box;
        // padding: 0 80px;
    }
}