@import "../../../../app/styles/vars";
@import "../../../../app/styles/media-queries.scss";

$heightBlock-default: 65px;
$heightBlock-small: 50px;
$gapBlocks: 20px;

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.mortgageStepsBlockMobile {
  display: none;

  @include mobile-l {
    display: block;
    position: relative;
    width: 100%;
    height: 400px;
    margin: 20px auto;
  }

  @include mobile-m {
    height: 350px;
  }
}

.mortgageStepsMobile {
  position: absolute;
  height: $heightBlock-default;
  animation-duration: 0.6s;
  animation-fill-mode: both;

  @media (max-width: 390px) {
    height: $heightBlock-small;
  }
}

.first,
.third,
.fifth {
  right: 30px;
  width: calc(100% - 30px);
  animation-name: slideInFromLeft;
}

.second,
.fourth {
  left: 30px;
  width: calc(100% - 30px);
  animation-name: slideInFromRight;
}

.first {
  top: 0px;
  animation-delay: 0.2s;

  @media (max-width: 390px) {
    top: 0px;
  }
}

.second {
  top: calc(($heightBlock-default + $gapBlocks) * 1);
  animation-delay: 0.6s;

  @media (min-width: $mobile-m) and (max-width: 390px) {
    top: calc(($heightBlock-small + $gapBlocks) * 1);
  }
}

.third {
  top: calc(($heightBlock-default + $gapBlocks) * 2);
  animation-delay: 1s;

  @media (min-width: $mobile-m) and (max-width: 390px) {
    top: calc(($heightBlock-small + $gapBlocks) * 2);
  }
}

.fourth {
  top: calc(($heightBlock-default + $gapBlocks) * 3);
  animation-delay: 1.4s;

  @media (min-width: $mobile-m) and (max-width: 390px) {
    top: calc(($heightBlock-small + $gapBlocks) * 3);
  }
}

.fifth {
  top: calc(($heightBlock-default + $gapBlocks) * 4);
  animation-delay: 1.8s;

  @media (min-width: $mobile-m) and (max-width: 390px) {
    top: calc(($heightBlock-small + $gapBlocks) * 4);
  }
}