@import "../../../../../../app/styles/vars";
@import "../../../../../../app/styles/media-queries.scss";

.headerTopWithMenuContainer {
  width: 100%;
  background: $primary-gradient;
  height: 85px;
  position: fixed;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  min-width: 1040px;
  max-width: 100vw;
  padding: 0px 80px;
}

.headerTopWithMenuContainer::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 8px;
  background-color: $color-white-transparent;
}

.headerTopWithMenu {
  width: 100%;
  max-width: 1920px;
}

.titleItems {
  display: flex;
  align-items: center;
  gap: 30px;

  @include responsive($desktop-m) {
    gap: 50px;
  }

  @include responsive($desktop-l) {
    gap: 90px;
  }
}

.titleList {
  display: flex;
  align-items: flex-end;
  gap: 15px;

  @include responsive($desktop-m) {
    gap: 20px;
  }
  @include responsive($desktop-l) {
    gap: 30px;
  }
}