@import '../../../../app/styles/vars';
@import '../../../../app/styles/media-queries.scss';

.wrapper {
	margin: 0px;
	padding: 0px;
	height: 500px;
	gap: 9.12px;

	@include responsive($tablet-m) {
		height: 100%;
	}

	svg {
		flex-shrink: 0;
	}

	.mainContainer {
		margin: 0px;
		padding: 0px;
		height: 500px;
		padding: 0px 10px 50px 10px;
		background: $color-white;
		position: relative;

		@include responsive($tablet-m) {
			flex-direction: row;
			align-items: center;
			height: 270px;
			gap: 30px;
			padding: 0px;
			margin-bottom: 20px;

			> div:first-child {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		}

		form {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			height: 170px;

			@include responsive($tablet-m) {
				height: 240px;
				padding-bottom: 30px;
				padding-top: 50px;
				margin-right: 40px;
				
			}
		}

		.error {
			font-family: $font-main;
			margin-top: 5px;
			font-size: 12px;
			color: $color-primary;
		}
	}
}
