@import '../../../../app/styles/vars';
@import '../../../../app/styles/media-queries.scss';

.slickSliderContainer {
    width: 1680px;
    margin: 30px 0 80px 0;

    @include desktop-xl() {
        width: 1220px;
    }

    @include desktop-l() {
        width: 1100px;
    }

    @include desktop-m() {
        width: 860px;
    }

    .sliderItemContainer {
        width: 100%;
        height: 400px;
        padding: 30px 20px;
        border-radius: 5px;
        box-shadow: $block-shadow;
        
        @include desktop-xl() {
            height: 350px;
        }

        @include desktop-l() {
            height: 330px;
        }

        @include desktop-m() {
            height: 300px;
        }

        .slickSliderItem {
            height: 340px;
            opacity: 0.5;            
            overflow-y: auto;

            @include desktop-xl() {
                height: 290px;
            }

            @include desktop-l() {
                height: 270px;
            }

            @include desktop-m() {
                height: 240px;
            }

            &::-webkit-scrollbar-track {
                background-color: #f1f1f1;
                border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $color-gray-200;
                border-radius: 5px;

                &:hover {
                    background-color: lighten($color-gray-200, 10%);
                }
            }

            &::-webkit-scrollbar {
                width: 6px;
                border-radius: 5px;
            }

            img {
                width: 100%;
            }
        }

        .slickActive {
            opacity: 1;
            transform: scale(1);
        }
    }

    .slickSideSlide {
        transform: scale(0.8);
    }
}