@import '../../../../app//styles/vars';

.form {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 10px;
	gap: 20px;
}

.item {
	padding: 30px 20px;
	gap: 20px;

	> p {
		max-width: 270px;
		min-height: 70px;
		color: $color-black;
		text-align: center;
		font-family: $font-main;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 0.225px;
		text-transform: uppercase;
	}

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}
}

.error {
	font-family: $font-main;
	margin-top: 5px;
	font-size: 17px;
	color: $color-primary;
}
