@import "../../../../app/styles/vars";
@import "../../../../app/styles/media-queries.scss";

.right,
.left {
  background-color: $color-white;
  box-shadow: $block-shadow;
  height: 100%;
  width: 100%;
}

.right {
  padding-left: 20px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.left {
  padding-right: 20px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.right img {
  height: 40%;
  margin-right: 20px;
  @include mobile-m {
    margin-right: 10px;
  }
}

.left img {
  height: 40%;
  margin-left: 20px;
  @include mobile-m {
    margin-left: 10px;
  }
}

.textMortgageStepMobile {
  font-size: 20px;
  line-height: 1.2em;
  padding-left: 30px;
  @media (max-width: 520px) {
    font-size: 18px;
  }
  @media (max-width: 450px) {
    font-size: 16px;
  }
  @media (max-width: 390px) {
    font-size: 14px;
  }
  @include mobile-m {
    font-size: 13px;
    line-height: 1.1em;
  }
}

.right .textMortgageStepMobile {
  text-align: right;
  padding-right: 30px;
}
