@import "../../../../app/styles/vars";
@import "../../../../app/styles/media-queries.scss";

.headerContainer {
  width: 100%;
  background: linear-gradient(70deg, #BEBEBE 0%, #f2f1f1 25%, $color-secondary 25%);
  padding-top: 80px;
  overflow: hidden;
  @include tablet-l {
    padding-top: 0px;
  }
}

.headerContent {
  width: 100%;
  max-width: $desktop-xl;
  position: relative;
}

.headerInfoContainer {
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 60vw;
  z-index: 0;
}
