@import '../../../../app/styles/vars';

.loginForm {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    gap: 30px;

    .password_container {
        position: relative;

        .passwordToggle {
            position: absolute;
            top: 5px;
            right: 15px;
        }
    }
}

.error {
    font-family: $font-main;
    color: $color-primary;
    font-size: 15px;
}


