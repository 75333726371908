@import './normalize.scss';
@import './vars';
@import './media-queries';

body {
    font-family: $font-main;
    color: $color-black;
}

button {
    cursor: pointer;
}

.App {
    height: 100vh;
}

.calendly-badge-widget .calendly-badge-content {
    border-radius: 4px !important;
    font-family: $font-main !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}
