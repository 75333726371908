@import '../../../../app/styles/vars';
@import '../../../../app/styles/media-queries.scss';

.wrapper {
	position: sticky;
	bottom: 0;
	z-index: 10;
}
.nav {
	display: flex;
	padding: 0px 10px;
	justify-content: center;
	align-items: center;
	flex: 1 0 0;
	background: $primary-gradient;
	width: 100%;

	@include responsive($mobile-l) {
        gap: 20px;
    }

	@include responsive($tablet-m) {
        gap: 40px;
    }
}
