@import "../../../../app/styles/vars";

.contactsBlock {
  width: 100%;
  padding: 20px;
  gap: 22px;
}

.contactsIconsBlock {
  margin-top: 16px;
  gap: 22px;
  flex-wrap: wrap;
}

.contactIconBlock {
  gap: 10px;
}

.contactsText {
  text-align: center;
  font-size: clamp(16px, 2vw, 22px);
  margin-bottom: 30px;
  min-width: 300px;
}

.licenseText {
  max-width: 800px;
  text-align: center;
  font-size: clamp(12px, 2vw, 16px);
  margin-top: 30px;
}