//шрифты
@import "@fontsource/carlito"; // Defaults to weight 400
@import "@fontsource/carlito/400.css"; // Specify weight
@import "@fontsource/carlito/400-italic.css";
@import './fonts.scss';

$font-main: 'Carlito', sans-serif;
$font-title: 'Alta', sans-serif;

// Основные цвета
$color-white: #ffffff;
$color-black: #000000;
$color-secondary: #fff6ea;
$color-primary: #990b31;
$color-primary-light: #cc0d49;
$color-primary-dark: #7b0827;
$color-navbar-btn: #aa3454;
$color-white-transparent: rgba(255, 255, 255, 0.5);
$color-black-transparent: rgba(0, 0, 0, 0.20);

// Цвета для шкалы серого
$color-gray-100: #f4f6f8;
$color-gray-200: #c1c9d2;
$color-gray-300: #a3acb9;
$color-gray-600: #4f566b;

// Градиенты
$primary-gradient: linear-gradient(
  29deg,
  #990b31 0%,
  #990b31 29.8%,
  #720724 100%
);

$primary-gradient-animation1: linear-gradient(
  35deg,
  #7b0827 0%,
  #990b31 35%,
  #cc0d49 50%,
  #990b31 65%,
  #7b0827 100%
);

$primary-gradient-animation: linear-gradient(35deg, rgba(255,246,234,1) 10%, rgba(204,13,73,1) 20%, rgba(153,11,49,1) 30%, rgba(170,52,84,1) 40%, rgba(123,8,39,1) 50%, rgba(170,52,84,1) 60%, rgba(153,11,49,1) 70%, rgba(204,13,73,1) 80%, rgba(255,246,234,1) 90%);
$primary-gradient-light: linear-gradient(180deg, #cc0d49 0%, #7c002a 96.06%);

// Тени для кнопок и блоков
  $red-button-shadow: 0 1px 4px rgba(54, 12, 12, 0.25);
  $gray-button-shadow: 0 1px 4px rgba(121, 117, 117, 0.25);
  $red-button-inner-shadow: inset 0 0 6px rgba(97, 11, 34, 0.55);
  $block-shadow: 0 3px 15px #a3acb9,
  0 0 1.5px rgba(54, 59, 100, 0.1);

//миксины 
@mixin button-font {
  font-family: "Carlito", sans-serif;
  font-weight: normal;
  line-height: 100%;
  font-size: 16px;
  letter-spacing: 0;
  margin: 0;
}

@mixin article-header-font {
  font-family: $font-title;
  font-weight: 500;
  text-transform: uppercase;
}

@mixin input-font {
  font-family: $font-main;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0%;
}

@mixin questionnaire-font {
  font-family: $font-main;
  font-weight: 400;
  line-height: auto;
  font-size: 18px;
  letter-spacing: 1%;
}

@mixin content-font {
  font-family: $font-main;
  font-weight: normal;
  line-height: 140%;
  font-size: 20px; 
  letter-spacing: 1%;
}
