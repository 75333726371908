@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "../../../../app/styles/vars";


.slick-list {
    padding: 20px 0 !important;
}

.slick-dots li button:before {
    font-size: 20px;
    color: $color-gray-200 !important;
}

.slick-dots .slick-active button:before {
    color: $color-gray-300 !important;
}

.slick-next,
.slick-prev {
    width: 32px;
    height: 32px;
}

.slick-next {
    margin-right: -20px;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 15 15%27 fill=%27%23c1c9d2%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat;
}

.slick-prev {
    margin-left: -20px;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 15 15%27 fill=%27%23c1c9d2%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e") !important;
}

.slick-next:before,
.slick-prev:before {
    content: "";
}