@import "../../../../app/styles/_vars.scss";
@import "../../../../app/styles/media-queries.scss";

.statisticItem {
  text-align: center;
}

.statisticItemCount {
  font-size: clamp(60px, 5.5vw, 80px);
  line-height: 0.7em;
  margin-bottom: 15px;

  @include tablet-l {
    font-size: clamp(2.5rem, 8vw, 80px);
  }
}

.statisticItemText {
  font-size: clamp(16px, 1.4vw, 24px);
  line-height: 1.1em;
  white-space: pre-line;
  
  @include tablet-l {
    font-size: clamp(10px, 2.5vw, 20px);
  }
}
