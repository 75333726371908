@import "../../../../app/styles/_vars.scss";
@import "../../../../app/styles/media-queries.scss";

.mainBlock {
  width: 100%;
  padding: 10px 0 30px 0;
  align-items: stretch;

  @include tablet-l {
    padding: 30px 0;
    background-color: $color-secondary;
  }
}

.itemWrapper {
  aspect-ratio: 11 / 9;
  flex: 1 1 33.33%;
  padding-top: 5%;
  &.loading {
    padding-top: 0;
    align-items: center; 
  }
  
  @include tablet-l {
    max-height: 220px;
  }
  @include tablet-m {
    max-height: 190px;
  }
  @include mobile-l {
    max-height: 150px;
  }
}

.itemWrapper:nth-child(2) {
  border-right: 3px solid $color-primary;
  border-left: 3px solid $color-primary;
}
