@import '../../../../app/styles/vars'; 
@import '../../../../app/styles/media-queries.scss';

.calculatorBlock {
    width: 320px;
    height: 130px;
    @include responsive($tablet-m) {
        width: 380px;
        gap: 10px;
    }

    @include responsive($tablet-l) {
        width: 440px;
        gap: 20px;
    }

    @include responsive($desktop-l) {
        width:clamp(440px, 30vw, 540px);
        gap: 30px;
    }
    @include responsive($desktop-xl) {
        width: 580px;
    }
}

.calculatorInputBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    padding: 10px 0px 10px 0px;;
    font-family: $font-main;

    @include responsive($tablet-m) {
        width: 350px;
        height: 70px;
    }

    @include responsive($tablet-l) {
        width: 440px;
        gap: 20px;
    }

    @include responsive($desktop-l) {
        width:clamp(440px, 30vw, 540px);
    }

    @include responsive($desktop-xl) {
        width: 580px;
    }
}


.label {
    width: 120px;
    color: $color-black;
    font-style: normal;
    font-weight: 400;
    font-family: $font-main;
    line-height: 15px;
    white-space: pre;
    @include responsive-font-size(14px, 16px, 18px, 20px);

    @include responsive($tablet-m) {
        width: 130px;
    }

    @include responsive($tablet-l) {
        width: 150px;
        line-height: 20px;
        font-size: clamp(20px, 1.4vw, 28px);
    }
    
    @include responsive($desktop-xl) {
        width: 200px;
    }
}

.txt {
    width: 65px;
    text-align: end;
    color: $color-black;
    font-style: normal;
    font-weight: 400;
    font-family: $font-main;
    @include responsive-font-size(12px, 14px, 16px, 18px);

    @include responsive($tablet-m) {
        width: 80px;
    }

    @include responsive($tablet-l) {
        width: 90px;
    }

    @include responsive($desktop-xl) {
        width: 120px;
    }
}