@import "../../app/styles/vars";
@import "../../app/styles/media-queries.scss";

.aboutMe {
  margin-top: 70px;
  padding-bottom: 70px;
  
  @include desktop-l {
    margin-top: 50px;
  }
  @include tablet-l {
    margin-top: 30px;
  }
  @include mobile-l {
    margin-top: 10px;
  }
  @include mobile-m {
    margin-top: 0px;
  }
}