@import '../../../../app/styles/vars';
@import '../../../../app/styles/media-queries.scss';

.rangeStyle {
    position: relative;
    width: 300px;
    height: 63px;

    @include responsive($tablet-m) {
        width: 340px;
    }

    @include responsive($tablet-l) {
        width: 440px;
        height: 70px;
    }

    @include responsive($desktop-l) {
        width:clamp(440px, 30vw, 540px);
    }

    @include responsive($desktop-xl) {
        width: 580px;
    }


    .rangeTrack {
        position: absolute;
        left: 0;
        height: 5px;
        border-radius: 5.172px;
        background-color: $color-gray-300;
        width: calc(min(var(--value) * 100%, 100%)); 
        z-index: 0;
    }

    .rangeNumber {
        position: absolute;
        border-radius: 2.5px;
        padding: 2px 4px;
        background-color: $color-gray-100;
        top: 5px;
        left: calc(min(var(--value) * 84%, 100%));
        color:  $color-gray-600;
        text-align: center;
        line-height: 10.345px;
        @include responsive-font-size(12px, 12px, 15px, 18px);


        @include responsive($tablet-m) {
            height: 15px;
            top: 0px;
            left: calc(min(var(--value) * 86%, 100%));
        }

        @include responsive($tablet-l) {
            height: 15px;
            width: 70px;
            top: -5px;
            left: calc(min(var(--value) * 84%, 100%));
        }

        @include responsive($desktop-xl) {
            top: -15px;
            height: 20px;
            width: 80px;
        }

        &.rangeNumberDuration {
            left: calc(min(var(--value) * 95%, 100%));
            width: auto;
        }
    }

    .rangeLabel {
        position: absolute;
        color: $color-gray-600;
        bottom: 5px;
        @include responsive-font-size(14px, 14px, 15px, 18px);

        @include responsive($tablet-m) {
            bottom: 0px;
        }

        @include responsive($tablet-l) {
            bottom: -5px;
        }

        @include responsive($desktop-xl) {
            bottom: -15px;
        }

        &:first-child {
            left: 3px;
        }
        
        &:last-child {
            right: 0px;
        }
    }

}
