@import "../../../../app/styles/vars";
@import "../../../../app/styles/media-queries.scss";

.photoContainer {
  height: 75vw;
  max-height: 1000px;
  margin-top: -40px;
  position: relative;
  overflow: hidden;

  @include desktop-xl {
    margin-left: -80px;
    max-height: 900px;
    @media (min-width: 1800px) {
      margin-left: 0px;
      max-height: 1000px;
    }
    @media (-webkit-min-device-pixel-ratio: 2) {
      margin-top: -80px;
    }
  }
  @include desktop-l {
    margin-left: -110px;
    max-height: 850px;
  }
  @include desktop-m {
    margin-left: -110px;
  }
  @include tablet-l {
    margin-top: -80px;
    max-height: 650px;
    margin-left: -60px;
    @media (min-width: 980px) {
      margin-left: -30px;
      max-height: 700px;
    }
  }
  @include tablet-m {
    margin-top: -80px;
    margin-left: -50px;
  }
  @include mobile-l {
    margin-top: -60px;
    margin-left: -50px;
    min-height: 330px;
  }
  @include mobile-m {
    min-height: 280px;
  }
}

.photoContainer img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 50px
  );
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 50px
  );
  @include mobile-l {
    width: auto;
  }
}
