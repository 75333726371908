@import "../../../app/styles/vars";
@import "../../../app/styles/media-queries.scss";

.contentText {
  font-size: clamp(18px, 1.4vw, 28px);
  width: 100%;
  max-width: 1920px;
  text-align: justify;
  white-space: pre-line;
  word-wrap: break-word;
  padding: 0 80px;
  line-height: 1.5em;
  position: relative; 
  
  @include desktop-l {
    padding: 0 60px;
    font-size: clamp(18px, 2vw, 28px);
  }
  @include desktop-m {
    font-size: clamp(18px, 2.4vw, 26px);
    padding: 0 50px;
  }
  @include tablet-l {
    font-size: clamp(18px, 2.7vw, 24px);
  }
  @include tablet-m {
    font-size: clamp(18px, 2.9vw, 22px);
    padding: 0 40px;
  }
  @include mobile-l {
    font-size: clamp(18px, 4vw, 22px);
    padding: 0 30px;
  }
  @include mobile-m {
    padding: 0 10px;
  }
}

.paragraph {
  margin-bottom: 20px; 
  text-indent: 2em;
}