@import '../../../../../../app/styles/vars';

.titleItem {
    text-transform: uppercase;
    font-family: $font-main;
    color: $color-white;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
    font-size: 16px;
}