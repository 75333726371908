@import "../../../../app/styles/vars";
@import "../../../../app/styles/media-queries.scss";

.headerSideSlogans {}

.topSloganContainer {
  max-width: 1280px;
  background-color: $color-white-transparent;
  @include article-header-font;
  text-align: right;
  width: 70vw;
  padding: 0 80px 0 0;
  font-size: clamp(25px, 5vw, 100px);
  clip-path: polygon(0 0, 2% 100%, 100% 100%, 100% 0);
  margin-bottom: 10px;
  &[lang="ru"] {
    font-size: clamp(25px, 5vw, 73px);
  }

  @include desktop-xl {
    width: 65vw;
    font-size: 5.5vw;
    clip-path: polygon(0 0, 2.2% 100%, 100% 100%, 100% 0);
    &[lang="ru"] {
      font-size: 4.5vw;
    }
  }
  @include desktop-m {
    width: 60vw;
    padding: 20px 50px 15px 30px;
    font-size: clamp(25px, 7.5vw, 80px);
    line-height: 1.1em;
    clip-path: polygon(0 0, 4% 100%, 100% 100%, 100% 0);
    &[lang="ru"] {
      font-size: 6.5vw;
      line-height: 1.2em;
      @media (min-width: 1150px) {
        font-size: 5.5vw;
      }
    }
  }
  @include tablet-l {
    width: 55vw;
    padding: 20px 50px 15px 0;
    font-size: 6.5vw;
    line-height: 1.3em;
    clip-path: polygon(0 0, 5.5% 100%, 100% 100%, 100% 0);
    margin-bottom: 30px;
  }
  @include tablet-m {
    width: 55vw;
    padding: 25px 40px 25px 0;
    white-space: pre-wrap;
    clip-path: polygon(0 0, 5% 100%, 100% 100%, 100% 0);
    margin-bottom: 20px;
    &[lang="ru"] {
      font-size: 6.5vw;
    }
  }
  @include mobile-l {
    width: 57vw;
    padding: 10px 30px 15px 0;
    font-size: 9vw;
    clip-path: polygon(0 0, 7% 100%, 100% 100%, 100% 0);
    margin-bottom: 15px;
    &[lang="ru"] {
      font-size: 6.5vw;
      padding: 20px 30px 20px 0;
    }
  }
  @include mobile-m {
    width: 59vw;
    font-size: 7.5vw;
    white-space: normal;
    padding: 10px 15px 10px 0;
    &[lang="ru"] {
      font-size: 6.5vw;
      padding: 10px 15px 10px 0;
    }
  }
}

.bottomSloganContainer {
  background-color: $color-white-transparent;
  @include article-header-font;
  color: $color-primary;
  text-align: right;
  width: 62vw;
  max-width: 1255px;
  padding: 10px 80px 10px 0;
  font-size: clamp(40px, 4vw, 60px);
  clip-path: polygon(0 0, 1.5% 100%, 100% 100%, 100% 0);
  margin-bottom: 10px;
  &[lang="ru"] {
    font-size: clamp(25px, 4vw, 35px);
  }

  @include desktop-xl {
    width: 63.3vw;
    font-size: 3vw;
    padding: 20px 70px 15px 0;
    clip-path: polygon(0 0, 1.8% 100%, 100% 100%, 100% 0);
    &[lang="ru"] {
      font-size: 2.3vw;
    }
  }
  @include desktop-m {
    font-size: 2.8vw;
    width: 57.5vw;
    padding: 20px 50px 15px 0;
    line-height: 1.3em;
    clip-path: polygon(0 0, 2% 100%, 100% 100%, 100% 0);
    &[lang="ru"] {
      font-size: 3.3vw;
      padding: 5px 40px 5px 0;
      @media (min-width: 1150px) {
        font-size: 3.1.2vw;
        line-height: 1.1em;
      }
    }
  }
  @include tablet-l {
    width: 51.5vw;
    padding: 30px 40px 30px 0;
    font-size: 4.5vw;
    line-height: 1.3em;
    clip-path: polygon(0 0, 4.5% 100%, 100% 100%, 100% 0);
    &[lang="ru"] {
      font-size: 4.5vw;
      padding: 20px 40px 20px 0;
    }
  }
  @include mobile-l {
    width: 52.5vw;
    padding: 10px 30px 10px 0;
    font-size: 5vw;
    line-height: 1.2em;
    white-space: pre-wrap;
    &[lang="ru"] {
      font-size: 4.5vw;
      line-height: 1.3em;
      padding: 15px 30px 15px 0;
    }
  }
  @include mobile-m {
    width: 55vw;
    padding: 10px 15px 10px 0;
    &[lang="ru"] {
      padding: 10px 15px 10px 0;
    }
  }
}
