@import "../../../../app/styles/vars";
@import "../../../../app/styles/media-queries.scss";

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.mortgageStepsBlock {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  align-items: stretch;
  padding: 20px 80px;
  flex-wrap: wrap;
  justify-content: space-between;

  @include desktop-l {
    gap: 3.5%;
  }

  @include mobile-l {
    display: none;
  }
}

.mortgageSteps {
  animation-duration: 0.6s;
  animation-fill-mode: both;
  animation-name: slideInFromRight;
  width: 31%;
  margin-bottom: 4%;
  @include tablet-l {
    width: 48%;
  }
  @include mobile-l {
    width: 100%;
  }
}

.first {
  animation-delay: 0.2s;
}

.second {
  animation-delay: 0.6s;
}

.third {
  animation-delay: 1s;
}

.fourth {
  animation-delay: 1.4s;
}

.fifth {
  animation-delay: 1.8s;
}

.six {
  animation-delay: 2.2s;
}
