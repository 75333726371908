@import '../../app/styles/vars';

.errorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding-top: 10vh;

    .errorNum {
        color: $color-primary;
        font-family: Alta;
        font-size: 96px;
        font-weight: 400;
        margin-bottom: 0;
    }

    p {
        color: $color-black;
        margin: -5px 0 20px 0;
        font-family: Carlito;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.13px;
    }

    @media all and (max-width: 800px) {    
        .errorNum {
            font-size: 80px;
        }
        
        p {
            margin: -5px 0 15px 0;
            font-size: 13px;
        }
    }
}
